import React, {useState} from "react";
import "./Toggle.scss";

const Toggle = (props) => {
  let [selected,setSelected] = useState(0);
  console.log(props);
  return(
    <div className="toggle">
      {
        props.toggle.map((toggle,index)=>(
          <div className={"toggleBloc "+(index===selected?"selected":"")} onClick={()=>{
            if(index!==selected){
              setSelected(index===selected?-1:index);
              if(props.onChange){props.onChange(index)}
            }
          }}>
            <div className="toggleTitle">{toggle.title}</div>
            <div className="toggleContent">
              {
                toggle.items.map((item)=>item)
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}
export default Toggle;
