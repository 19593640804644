import React, {useState} from "react"

import Layout from "../components/Layout"
import Toggle from "../components/Toggle/Toggle"
import GitHubButton from 'react-github-btn'
import "./index.scss"
import github from  "../images/github.svg";
import twitterSvg from  "../images/twitter.svg";
import france from  "../images/france.png";
import love from  "../images/love.png";
import hero from  "../images/kelioSearch.svg";
import cmd from  "../images/cmd.svg";
import integromatWH from  "../images/IntegromatWH.png";
import iftttWH from  "../images/IftttWH.png";
import n8nWH from  "../images/n8nWH.png";
import zapierWH from  "../images/ZapierWH.png";
import linkedin from "../images/linkedin.png";
import evernote from "../images/evernote.png";
import gmail from "../images/gmail.png";
import hubspot from "../images/hubspot.png";
import hunter from "../images/hunter.png";
import medium from "../images/medium.png";
import slack from "../images/slack.png";
import todoist from "../images/todoist.png";
import trello from "../images/trello.png";
import twake from "../images/twake.png";
import twitter from "../images/twitter.png";
import mail from "../images/mail.png";
import pdf from "../images/pdf.png";
import opensource from "../images/opensource.png";

const IndexPage = () => {
  let [imgSelected, setImgSelected] = useState(0);
  const useCases = [
    {
      title : "Linkedin X CRM",
      tools : [linkedin,hunter,hubspot],
      description : "Are you interested in a prospect? Register him in your CRM with his email address.",
      available : ["zapier","n8n"]
    },
    {
      title : "Medium X Slack",
      tools : [medium,slack],
      description : "Something to share? Send it on the channel of your choice.",
      available : ["zapier","n8n","integromat"]
    },
    {
      title : "Linkedin X Trello",
      tools : [linkedin,trello],
      description : "Add the profile you consult to your recruitment board.",
      available : ["zapier","n8n","integromat"]
    },
    {
      title : "Medium X Evernote",
      tools : [medium,evernote],
      description : "Save what you selected in a new evernote note",
      available : ["zapier","n8n","integromat"]
    },
    {
      title : "Twitter X Twake",
      tools : [twitter,twake],
      description : "Share the Tweet you watch on your Twake channel.",
      available : ["zapier","n8n","integromat"]
    },
    {
      title : "Mail X Todoist",
      tools : [gmail,todoist,slack],
      description : "Add an email to your to-do list on Todoist and let your colleagues know that you are handling it.",
      available : ["zapier","n8n","integromat"]
    },
    {
      title : "PDF X Mail",
      tools : [pdf,mail],
      description : "Send what you are looking by email.",
      available : ["zapier","n8n","integromat"]
    },
  ]

  return(
    <Layout>
      <header>
        <section>
          <div className="container" style={{justifyContent: "flex-end"}}>
            <a href="#usecase">Use case</a>
            <a href="https://www.notion.so/Welcome-7d0f87fd2eb6462ea16b71ff5525deea">Doc</a>
            <a href="#getstarted">Get started</a>
          </div>
        </section>
      </header>
      <section className="sectionWithPadding hero">
        <div className="container">
          <div className="col">
            <h1 className="title" style={{padding:""}}>
              CMD + K for your browser
            </h1>
            <div className="subtitle">Super charge your browser. Launch your  Zapier, Ifttt, Integromat, n8n workflows from your browser.</div>
            <a href="https://github.com/kelio-app/kelio"><div className="cta">Get it for free</div></a>
          </div>
          <div className="col cmdKContent">
            {false && <div className="" style={{textAlign:"center",padding:"85px 0px"}}>
              <img className="cmdStep1" style={{verticalAlign: "sub",width:"32px"}} src={cmd}/>
              <span className="cmdStep2" style={{color: "#c4c4c4",fontSize:"32px",width:"32px",margin:"16px"}}>+</span>
              <span className="cmdStep2" style={{color: "#c4c4c4",fontSize:"32px",width:"32px"}}>K</span>
            </div>}
            <img className="cmdStep3 imgHero shadowed" src={hero} />
          </div>
        </div>
      </section>
      <section className="sectionWithPadding works">
        <div className="container">
          <div className="col">
            <h2 className="title">
              Connect your workflows
              <div className="textAbsolute">
                Connect your workflows
              </div>
              <div className="border" style={{background:"#FDA3A3"}}></div>
            </h2>
            <div className="subtitle">
            <Toggle
            toggle={[
              {
                title : "1. Build webhook trigger on your workflow provider",
                items : [
                  <a onClick={()=>setImgSelected(0)}>Zapier</a>,
                  <a onClick={()=>setImgSelected(1)}>N8N</a>,
                  <a onClick={()=>setImgSelected(2)}>Integromat</a>,
                  <a onClick={()=>setImgSelected(3)}>Ifttt</a>
                ]
              },
              {
                title : "2. Add your workflow on Kelio",
                items : [
                  <div className="">Add your workflow on Kelio by the extension. Set wich parameters you want to send. It can be a manual value given to each call, a fixed value or an automatic value searched in the active page.  </div>
                ]
              },
              {
                title : "3. Run it where you want",
                items : [
                  <div className="">Use cmd (or ctrl) + K on any website to launch your workflows 🚀</div>
                ]
              },
            ]}
            onChange={(index)=>{
              if(index===0){
                setImgSelected(0);
              }else{
                setImgSelected(index+3);
              }
            }}
            />
            </div>
          </div>
          <div className="col">
            <div className="imgHowContainer">
              {imgSelected===0 && <img alt="Zapier webhook" className="workflowImg shadowed" src={zapierWH} />}
              {imgSelected===1 && <img alt="N8N webhook" className="workflowImg shadowed" src={n8nWH} />}
              {imgSelected===2 && <img alt="Integromat webhook" className="workflowImg shadowed" src={integromatWH} />}
              {imgSelected===3 && <img alt="Ifttt webhook" className="workflowImg shadowed" src={iftttWH} />}
              {imgSelected===4 && <img alt="Zapier webhook" className="workflowImg shadowed" src={integromatWH} />}
              {imgSelected===5 && <img alt="Zapier webhook" className="workflowImg shadowed" src={integromatWH} />}
            </div>
          </div>
        </div>
      </section>
      <section className="sectionWithPadding use" id="usecase">
        <div className="container">
          <div className="col colAll">
            <h2 className="title">
              How to use
              <div className="textAbsolute">
                How to use
              </div>
              <div className="border" style={{background:"#C0ECFF"}}></div>
            </h2>
            <div className="useCases">
              {
                useCases.map((usecase)=><UseCase usecase={usecase} />)
              }
            </div>
          </div>
        </div>
      </section>
      <section className="sectionWithPadding opensource">
        <div className="container">
          <div className="col">
            <h2 className="title">
              Open source
              <div className="textAbsolute">
                Open source
              </div>
              <div className="border" style={{background:"#fbbfbf"}}></div>
            </h2>
            <div className="subtitle">Kelio is free and open source under the Apache 2.0 license. We believe in the power of open source for innovation. Come and help us on github.</div>
              <span style={{marginRight:"8px"}}>
                <GitHubButton href="https://github.com/kelio-app/kelio" data-color-scheme="no-preference: light; light: light; dark: light;" data-icon="octicon-star" data-show-count="true" aria-label="Star kelioapp/kelio on GitHub">Star</GitHubButton>
              </span>
              <GitHubButton href="https://github.com/kelio-app/kelio/fork" data-icon="octicon-repo-forked" aria-label="Fork kelio-app/kelio on GitHub">Fork</GitHubButton>
            </div>
          <div className="col">
            <img className="imgOpensource shadowed" src={opensource} />
          </div>
        </div>
      </section>
      <section className="sectionWithPadding">
        <div className="container">
          <div className="col colAll" style={{textAlign:"center"}}>
            <h2>Let's go to save time on your browser</h2>
            <div style={{marginTop : "12px"}}>
              <a href="https://github.com/kelio-app/kelio"><div className="cta">Get Kelio for free</div></a>
            </div>
          </div>
        </div>
      </section>
      <section className="" style={{padding:"72px 0px"}}>
        <div className="container">
          <div className="col colAll" style={{textAlign:"center"}}>
            <div >
              Made with <img className="smallImg" src={love}/> in France <img className="smallImg" src={france}/>
            </div>
            <div className="social">
              Follow us
              <a href="https://github.com/Kelio-app"><img className="smallImg" src={github}/>kelio-app</a>
              <a href="https://twitter.com/Kelioapp"><img className="smallImg" src={twitterSvg}/>kelioapp</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const UseCase = (props) => (
  <div className="useCase">
    <div className="useCaseTitle">{props.usecase.title}</div>
    <div className="useCaseTools">{props.usecase.tools.map((tool)=><img alt="available" className="useCaseTool" src={tool} />)}</div>
    <div className="useCaseDescription">{props.usecase.description}</div>
    <div className="useCaseAvailable"><span>Available on :</span> {props.usecase.available.map((av)=><span style={{margin:"0px 2px"}}>{av}</span>)}</div>
  </div>
)








export default IndexPage
